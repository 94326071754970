<template>
  <div class="settlement-manager-container">
    <!-- 超管端页面 -->
    <div v-if="role === 'SUPER_ADMIN' || (adminUserId != null && adminUserId == 1)">
      <superManager></superManager>
    </div>
    <!-- 普通用户 -->
    <div v-else>
      <ADXSettlementNav />
      <ADXSettlementReport />
    </div>
  </div>
</template>

<script>
import ADXSettlementNav from './ADXSettlementNav'
import { mapState } from 'vuex'
import ADXSettlementReport from './ADXSettlementReport'
import superManager from './superManager'
export default {
  name: 'ADXSettlementManager',
  components: { ADXSettlementNav, ADXSettlementReport, superManager },
  data () {
    return {
      query: {}
    }
  },
  computed: {
    ...mapState({
      adminUserId: (state) => state.user.adminUserId,
      showPlat: (state) => state.user.showPlat,
      role: (state) => state.user.roles[0]
    })
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.settlement-manager-container {
  border-radius: 10px;
  padding: 0 10px 10px 10px;
  background-color: #f0f2f5;
}
</style>
